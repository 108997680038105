import React from "react";

const NPSDashboardIcon = (props) => {
  return (
    <svg
      viewBox="0 0 20 17"
      fill={props.iconName === props.pageName ? "#00AC69" : "transparent"}
      className="w-[16px] opacity-70"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.73885 4.19575V10.1357H4.68706H1.16306H0.751241C0.750475 10.1238 0.75 10.1103 0.75 10.095V6.09672C0.75 5.3729 0.970784 4.91521 1.24671 4.63984C1.52609 4.36103 1.93533 4.19575 2.44702 4.19575H4.73885ZM2.08909 11.6357H4.07806L4.91756 15.6376L4.91752 15.6376L4.91906 15.6446C4.96625 15.8594 4.92729 16.046 4.86702 16.1532C4.83869 16.2036 4.81182 16.2265 4.79739 16.2358C4.7866 16.2427 4.77234 16.2495 4.74277 16.25H3.25965C3.20186 16.25 3.17876 16.238 3.16291 16.2256C3.14658 16.2129 3.05771 16.1369 2.98627 15.8598L2.08909 11.6357ZM7.42236 10.6235V3.75323L15.3437 0.802456V13.5678L7.42236 10.6235ZM15.5306 0.750268C15.5306 0.750284 15.5301 0.750253 15.5291 0.750129L15.5306 0.750268Z"
        stroke={props.iconName === props.pageName ? "#00AC69" : "#000C08"}
        strokeWidth="1.5"
      />
      <path
        d="M17.9609 6.52373H19.4142C20.2098 6.52373 20.1806 7.81379 19.4142 7.81379H17.9609C17.1328 7.81379 17.214 6.52373 17.9609 6.52373Z"
        fill={props.iconName === props.pageName ? "#00AC69" : "#000C08"}
      />
      <path
        d="M17.6313 3.52817L18.7863 2.72417C19.5139 2.25595 20.0233 3.29405 19.4139 3.78261L18.1952 4.63752C17.5676 4.99374 16.9491 3.9658 17.6313 3.52817Z"
        fill={props.iconName === props.pageName ? "#00AC69" : "#000C08"}
      />
      <path
        d="M18.099 9.65804L19.4467 10.621C19.9581 10.9935 19.51 12.0419 18.854 11.6204L17.4982 10.621C17.0841 10.2304 17.6443 9.32189 18.099 9.65804Z"
        fill={props.iconName === props.pageName ? "#00AC69" : "#000C08"}
      />
    </svg>
  );
};

export default NPSDashboardIcon;
